<template>
  <Layout>
    <div class="exp-legal exp-text-justify">
      <Container :small='true'>
        <h1>Nos mentions légales</h1>
        <p>
          La société Expansae est inscrite au tableau de l’Ordre des experts-comptables des Pays de la Loire en France.
        </p>
        <p>
          Nos missions sont conformes aux dispositions du Code de déontologie intégré au décret du 30 mars 2012 relatif à l’exercice de l’activité d’expertise comptable, de la norme professionnelle de « Maîtrise de la qualité », de la norme professionnelle relative aux obligations de la profession d’expertise comptable en matière de lutte contre le blanchiment des capitaux et le financement du terrorisme (NPLAB) élaborée en application des dispositions du Code monétaire et financier et, le cas échéant, de la norme professionnelle de travail spécifique à la mission considérée.
        </p>
        <p>
          La consultation du site internet www.expansae.com est subordonnée à l’acceptation intégrale et au respect des conditions d’utilisation suivantes :<br>
          L’internaute s’engage à faire un usage personnel et non commercial des informations contenues sur le site internet. En cas de non-respect des présentes dispositions par l’internaute, sa responsabilité pourrait se voir engagée.
        </p>
        <h2 class="exp-legal__title">
          Publication
        </h2>
        <h3>
          &Eacute;dition
        </h3>
        <p>
          Le site internet www.expansae.com est édité par la société Expansae, société par actions simplifiée au capital de <span class="exp-no-wrap">10 000</span> euros, immatriculée au Registre du commerce et des sociétés de Nantes sous le numéro 880 846 043 00011 dont le siège social est fixé au 18, avenue du Forgeron, à NANTES (44300).
        </p>
        <p>
          Le directeur de la publication du site internet est M. Nicolas GATÉ.
        </p>
        <p>
          La société Expansae et son directeur de publication peuvent être contactés pendant les heures de bureau :<br>
          - par email : <a href="mailto:ngate@expansae.com">ngate@expansae.com</a><br>
          - par téléphone : 06 88 85 92 96
        </p>
        <h3>
          Hébergement
        </h3>
        <p>
          Le site internet est hébergé par la société Netlify, Inc., société à capitaux privés régie par le Code fiscal des États-Unis d’Amérique, dont le siège social est fixé au 2325 3rd Street, Suite 296, San Francisco, en Californie (94107).
        </p>
        <h3>
          Développement
        </h3>
        <p>
          Le site internet a été développé par la société Expansae, société par actions simplifiée au capital de <span class="exp-no-wrap">10 000</span> euros, immatriculée au Registre du commerce et des sociétés de Nantes sous le numéro 880 846 043 00011 dont le siège social est fixé au 18, avenue du Forgeron, à NANTES (44300).
        </p>
        <p>
          La société Expansae et son directeur de publication peuvent être contactés pendant les heures de bureau :<br>
          - par email : <a href="mailto:ngate@expansae.com">ngate@expansae.com</a><br>
          - par téléphone : 06 88 85 92 96
        </p>
        <h2 class="exp-legal__title">
          Propriété intellectuelle
        </h2>
        <p>
          Le site internet est la propriété exclusive de la société Expansae.
        </p>
        <h3>
          Droits d’auteur
        </h3>
        <p>
          Le site internet constitue une œuvre originale et la société Expansae est titulaire des droits d’auteur sur celle-ci, au regard des articles L111-1 et suivants du Code de la propriété intellectuelle.
        </p>
        <p>
          Il est rappelé que toutes images, textes ou éléments graphiques contenus sur le présent site internet peuvent constituer des œuvres de l’esprit, bénéficiant ainsi de la protection au titre du droit d’auteur, selon les dispositions de ce même Code.<br>
          Toute représentation ou reproduction, totale ou partielle, permanente ou temporaire, sur un support informatique et/ou papier, et par quelque procédé que ce soit (notamment par voie de liens hypertextes ou framing), de l’un ou l’autre des éléments du site internet, sans l’accord préalable et exprès de la société Expansae, est interdite et constitue un acte de contrefaçon, qui pourra entraîner des condamnations civiles et/ou pénales, selon les articles L331-1 et suivants et L335-2 et suivants du Code de la propriété intellectuelle, sous réserve des exceptions mentionnées à l’article L122-5 de ce même Code.
        </p>
        <h3>
          Droit des marques
        </h3>
        <p>
          L’enregistrement de la marque française Expansae auprès des services de l’INPI sous le numéro 19 4 607 567, en date du 10 avril 2020,  lui confère une exclusivité d’exploitation. Toute reproduction totale ou partielle, voire imitation, de la marque effectuée à partir des éléments du site internet, sans l’autorisation expresse de la société Expansae, est donc prohibée, au sens des articles L.713-2 et L.713-3 du Code de la propriété intellectuelle.
        </p>
        <h3>
          Liens hypertextes
        </h3>
        <p>
          L’établissement de liens hypertextes vers le site internet ou l’un de ses éléments nécessite une autorisation préalable et écrite de la société Expansae.
        </p>
        <h3>
          Protection des données personnelles
        </h3>
        <p>
          Dans le cadre de l’utilisation du site internet notamment, la société Expansae est amenée à opérer des traitements de données à caractère personnel, notamment à travers le formulaire « contact ».
        </p>
        <p>
          Elle respecte à ce titre les dispositions du RGPD (Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel et à la libre circulation de ces données) et de la loi du 6 janvier 1978, dite Loi informatique et Libertés modifiée.
        </p>
        <p>
          Le site internet ne collecte pas de cookies.
        </p>
        <h2 class="exp-legal__title">
          Dispositions diverses
        </h2>
        <h3>
          Fonctionnement
        </h3>
        <p>
          La société Expansae décline toute responsabilité quant aux éventuels dysfonctionnements pouvant survenir sur le site internet et entraîner une perte de données. L’internaute est seul responsable de l’utilisation qu’il fait du contenu du site internet.
        </p>
        <h3>
          Contenu
        </h3>
        <p>
          Les éléments présentés sur le site internet sont susceptibles de modification et de suppression, sans préavis. Ils sont mis à la disposition des internautes sans aucune garantie d’aucune sorte, expresse ou tacite.
        </p>
        <p>
          La société Expansae ne peut garantir l’exhaustivité et la véracité des informations présentes sur le site internet. De la même manière, elle ne peut garantir l’absence de modification par un tiers (virus, cheval de Troie).
        </p>
        <h3>
          Présence de liens hypertextes
        </h3>
        <p>
          La société Expansae n’est en aucun cas responsable du contenu, des produits ou des services proposés sur les sites internet auxquels son propre site internet se trouverait lié par des liens hypertextes (framing) ou tout autre type de lien. La présence de liens hypertextes, quelle que soit l’existence préalable ou non de l’accord de la société Expansae, ne crée pas une solidarité de responsabilité entre celle-ci et les propriétaires des autres sites internet quant au contenu des sites sur lesquels l’internaute est redirigé.
        </p>
        <h3>
          Droit applicable et compétence judiciaire
        </h3>
        <p>
          L’ensemble des informations diffusées sur le site internet et l’utilisation qui en est faite sont soumis exclusivement au droit français. Seuls les tribunaux relevant du ressort de la Cour d’appel du siège social de la société Expansae sont compétents pour connaître des éventuels litiges liés à l’utilisation du site internet.
        </p>
      </Container>
    </div>
  </Layout>
</template>

<style lang="scss">
  .exp-legal {
    padding: 80px 0;
    &__title {
      margin-top: 40px;
      margin-bottom: 12px;
      text-transform: uppercase;
    }
  }
</style>

<script>
import Container from '../components/layout/Container.vue';
import Layout from '../components/layout/Layout.vue';

  export default {
    name: 'LegalNotice',

    components: {
      Container,
      Layout,
    }
  }
</script>
<template>
  <div class="exp-split-layout">
    <div class="exp-split-layout__left">
      <slot name='left'></slot>
    </div>
    <div class="exp-split-layout__right">
      <slot name='right'></slot>
    </div>
  </div>
</template>

<style lang="scss">
  .exp-split-layout {
    width: 100%;
    @media (min-width: $breakpoint-large) {
      display: table;
      table-layout: fixed;
    }
    &__left,
    &__right {
      padding: 40px 20px;
      @media (min-width: $breakpoint-large) {
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
</style>

<script>
  export default {
    name: 'SplitLayout',
  }
</script>
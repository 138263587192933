<template>
  <div class="exp-footer">
    <Container>
      <SplitLayout>
        <template v-slot:left>
          <router-link :to="{ name: 'landing'}">
            <Logo class="exp-footer__logo"/>
          </router-link>
        </template>
        <template v-slot:right>
          <div class="exp-footer__content">
            <router-link :to="{ name: 'legalNotice' }" class="exp-footer__link">Mentions légales</router-link>
            <a href="https://isuiteexpansae.coaxis.com/isuiteexpert/?CNX=CNX97&CAB=0000" target="_blank" rel="noopener" class="exp-footer__link">Connexion</a>
          </div>

        </template>
      </SplitLayout>
    </Container>
  </div>
</template>

<style lang="scss">
  .exp-footer {
    background-color: $light-grey;
    &__logo {
      margin: auto;
      display: block;
      margin-top: 10px;
      height: 24px;
      @media (min-width: $breakpoint-large) {
        margin-top: 0;
        display: inline-block;
      }
    }
    &__content {
      @media (min-width: $breakpoint-large) {
        text-align: right;
      }
    }
    &__link {
      text-transform: uppercase;
      font-size: 14px;
      color: grey;
      line-height: 30px;
      letter-spacing: 1.25px;
      text-decoration: none;
      transition: all 0.3s;
      display: block;
      margin-bottom: 10px;
      text-align: center;
      @media (min-width: $breakpoint-large) {
        text-align: left;
        margin-left: 30px;
        margin-bottom: 0;
        display: inline-block;
      }
      &:hover,
      &:focus {
        color: $dark-blue;
        transiton: all 0.3s;
      }
      &:focus,
      &:active {
        text-decoration: underline;
      }
      &:active {
        color: $text-color;
      }
    }
    .exp-split-layout__left,
    .exp-split-layout__right {
      @media (max-width: $breakpoint-large - 1) {
        padding: 10px;
      }
    }
  }
</style>

<script>
import Container from './layout/Container.vue';
import Logo from '../assets/svg/logo.vue';
import SplitLayout from './layout/SplitLayout.vue';

  export default {
    name: 'ExpFooter',

    components: {
      Container,
      Logo,
      SplitLayout,
    },
  }
</script>
<template>
  <div>
    <Navbar/>
      <slot></slot>
    <ExpFooter />
  </div>
</template>

<script>
import ExpFooter from '../../components/Footer.vue';
import Navbar from '../../components/Navbar.vue';

  export default {
    name: 'Layout',
    components: {
      ExpFooter,
      Navbar,
    },
  }
</script>